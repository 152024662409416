<template>
  <div>
    <v-tabs class="mb-2">
      <v-tab
        v-for="route in opciones"
        :key="route.name"
        :to="`/controles${route.url}`"
      >
        <v-icon left>{{ route.icon }}</v-icon>
        {{ route.name }}</v-tab
      >
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      opciones: [
        {
          name: "Estados estudios",
          url: "/estados_estudios",
          icon: "mdi-alert-circle-outline",
        },
        {
          name: "Instaladoras",
          url: "/instaladoras",
          icon: "mdi-progress-wrench",
        },
        { name: "Tipo Evento", url: "/tipo_evento", icon: "mdi-calendar" },
        {
          name: "Interés",
          url: "/niveles_interes",
          icon: "mdi-emoticon-happy-outline",
        },
        { name: "Empresas", url: "/empresas", icon: "mdi-office-building" },
        {
          name: "Formatos",
          url: "/formatos",
          icon: "mdi-file-document-multiple-outline",
        },
        {
          name: "Inversores",
          url: "/inversores",
          icon: "mdi-power-socket-eu",
        },
        {
          name: "Paneles",
          url: "/paneles",
          icon: "mdi-solar-panel",
        },
        {
          name: "Tipo Cliente",
          url: "/tipo_cliente",
          icon: "mdi-account",
        },
        // { name: 'Tipos contacto', url: '/tipos_contacto' },
      ],
    };
  },
};
</script>
